import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contractTranslation',
  standalone: false
})
export class ContractStatusTranslation implements PipeTransform {
  private contractTranslation = {
    3: 'Active',
    1: 'Dormant',
    0: 'Terminated'
  };
  transform(contractStatus: string): string {
    if (!contractStatus) {
      return '';
    }

    return this.contractTranslation[contractStatus] ?? '';
  }
}
