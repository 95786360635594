import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BaseRepublishDto, State, StateUtils } from '@coin/importer/dto/util';

import { Observable } from 'rxjs';
import { environment } from '@coin/shared/util-environments';
import { SimpleTableHeader } from '../../components/simple-table/simple-table.component';
import { ObjectRefresherPopupComponent } from '../../components/object-refresher-popup/object-refresher-popup.component';

import { ToastService } from '../../services/toast/toast.service';
import { FileTypeService } from '../../services/file-type/file-type.service';
import { RepublishService } from '../../services/republish/republish.service';

@Component({
  selector: 'ci-republish',
  templateUrl: './republish.component.html',
  styleUrls: ['./republish.component.scss'],
  standalone: false
})
export class RepublishComponent implements OnInit {
  public queryParameters$: Observable<Params> = this.route.queryParamMap;
  public isExpectedToChange = StateUtils.isExpectedToChange;
  public updaterIntervalInMs = environment.subscriptionIntervalInMs;
  public selectedTableHeaders: SimpleTableHeader[] = [
    {
      title: '',
      key: '',
      width: '50px'
    },
    {
      title: 'File Type',
      key: 'fileType',
      filterType: 'input',
      width: '2fr'
    },
    {
      title: 'Process Step',
      key: 'state',
      filterType: 'dropdown',
      values: Object.values(State),
      width: '3fr'
    },
    {
      title: 'Republished Elements',
      key: 'selectedElement', // ID or "All / Full Base"
      width: '2fr'
    },
    {
      title: 'Republished At',
      key: 'republishedAt',
      filterType: 'date',
      width: '3fr'
    },
    {
      title: 'Republished By',
      key: 'republishedBy',
      width: '3fr'
    }
  ];

  constructor(
    public republishService: RepublishService,
    private router: Router,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private toast: ToastService,
    private fileTypeService: FileTypeService
  ) {}

  async ngOnInit() {
    // TODO - needed?
    if (!this.fileTypeService.fileTypeSubject$.getValue()?.length) {
      await this.fileTypeService.loadFileTypesToSubject();
    }
  }

  loadSummary(element: BaseRepublishDto) {
    this.router.navigate([`republish_summary/${element.id}`]);
  }

  openObjectRefresherPopup(): void {
    const popup = this.matDialog.open(ObjectRefresherPopupComponent, {
      disableClose: true
    });
    error => {
      this.toast.error('Error while loading FileTypes');
      this.matDialog.closeAll();
    };
  }

  // TODO
  getProgressPercentage(message: string): number {
    const arr = message.split('/');
    const percent = parseInt(arr[0]) * (100 / parseInt(arr[1]));
    return Math.round(percent);
  }
}
