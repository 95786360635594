import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileTypeDto, getReducedOperators, ValidationRuleDto, ValidationRuleOperator, FileTypeSchemaType } from '@coin/importer/dto/util';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FileTypeService } from '../../services/file-type/file-type.service';
import { ToastService } from '../../services/toast/toast.service';
import { SimpleTableHeader } from '../simple-table/simple-table.component';

@Component({
  selector: 'ci-schema-validations-popup',
  templateUrl: './schema-validations-popup.component.html',
  styleUrls: ['./schema-validations-popup.component.scss'],
  standalone: false
})
export class SchemaValidationsPopupComponent implements OnInit, OnDestroy {
  loading = false;
  public schema: ValidationRuleDto[] = [];
  public fileTypeName: FileTypeDto['displayedName'];
  private inputErrors = {};
  private destroy$: Subject<void> = new Subject<void>();

  public schemaHeader: SimpleTableHeader[] = [
    {
      title: 'Attribute',
      width: '2fr',
      key: 'attribute',
      filterType: 'input',
      hideFilter: true,
      hideSorting: true
    },
    {
      title: 'Type',
      width: '1fr',
      key: 'type',
      hideSorting: true,
      hideFilter: true
    },
    {
      title: 'Operator',
      width: '2fr',
      key: 'operator',
      hideSorting: true,
      hideFilter: true
    },
    {
      title: 'Value',
      width: '2fr',
      key: 'compareValue',
      hideSorting: true,
      hideFilter: true
    },
    {
      title: 'Validation Name (no comma)',
      width: '2fr',
      key: 'validationName',
      hideSorting: true,
      hideFilter: true
    }
  ];

  constructor(
    public matDialogRef: MatDialogRef<SchemaValidationsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fileTypeService: FileTypeService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    if (this.data?.item?.id) {
      this.fileTypeName = this.data.item.displayedName;
      this.fileTypeService
        .getSchemaRules(this.data.item.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          validationRules => {
            this.schema = validationRules;
          },
          null,
          () => {
            this.loading = false;
          }
        );
    }
  }

  close() {
    this.matDialogRef.close();
  }

  save() {
    this.loading = true;
    this.fileTypeService
      .updateValidationRules(this.schema)
      .pipe(takeUntil(this.destroy$))
      .subscribe(isUpdated => {
        if (isUpdated) {
          this.close();
          this.toast.info('updated Validation Rules');
        } else {
          this.loading = true;
          this.toast.error('could not update attributes');
        }
      });
  }

  onDropdownChange(item: ValidationRuleDto) {
    item.compareValue = null;
    item.validationName = null;
  }

  getAttributeKey(item: ValidationRuleDto): string {
    return item.attribute;
  }
  getAttributeValue(item: ValidationRuleDto): string {
    return Object.values(item)[0].toString();
  }

  getOperatorList(item: ValidationRuleDto): ValidationRuleOperator[] {
    return getReducedOperators(item.type);
  }

  disableCompareValue(item: ValidationRuleDto): boolean {
    switch (item.operator) {
      case null:
      case ValidationRuleOperator.false:
      case ValidationRuleOperator.true:
      case ValidationRuleOperator.isNull:
      case ValidationRuleOperator.notNull:
        return true;
    }
    return false;
  }

  invalidValidationName(item: ValidationRuleDto): boolean {
    const result = item.operator && !item.validationName;
    this.inputErrors[`${item.id}_name`] = result;
    return result;
  }

  invalidParseableNumber(item: ValidationRuleDto): boolean {
    let result = false;

    if (!this.missingCompareValue(item)) {
      switch (item.operator) {
        case ValidationRuleOperator.eqLength:
        case ValidationRuleOperator.gtLength:
        case ValidationRuleOperator.ltLength:
        case ValidationRuleOperator.notLength:
        case ValidationRuleOperator.gt:
        case ValidationRuleOperator.lt:
          result = isNaN(parseInt(item.compareValue));
      }
      if (item.type === FileTypeSchemaType.float && (item.operator === ValidationRuleOperator.eq || item.operator === ValidationRuleOperator.not)) {
        result = isNaN(parseFloat(item.compareValue));
      }

      if (item.type === FileTypeSchemaType.int && item.operator === ValidationRuleOperator.eq) {
        result = isNaN(parseInt(item.compareValue));
      }
    }

    this.inputErrors[`${item.id}_parser`] = result;
    return result;
  }

  missingCompareValue(item: ValidationRuleDto): boolean {
    const result = !this.disableCompareValue(item) && !item.compareValue;
    this.inputErrors[`${item.id}_noVal`] = result;
    return result;
  }

  hasInputErrors(): boolean {
    return Object.values(this.inputErrors).includes(true);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
