import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TransferListEntryDto, TransferListEntryOrigin } from '@coin/importer/dto/util';
import { PopupAction } from '@coin/importer/common/util';
import { SimpleTableHeader } from '../../simple-table/simple-table.component';
import { TransferListService } from '../../../services/organisation-management/transfer-list.service';
import { TransferListEntryPopupComponent } from '../transfer-list-entry-popup/transfer-list-entry-popup.component';

@Component({
  selector: 'ci-transfer_list',
  templateUrl: './transfer_list.component.html',
  styleUrls: ['./transfer_list.component.scss'],
  standalone: false
})
export class TransferListComponent {
  public queryParameters$: Observable<Params> = this.route.queryParamMap;
  public updaterIntervalInMs = null;
  public selectedTableHeaders: SimpleTableHeader[] = [
    {
      title: 'Edit',
      width: '50px'
    },
    {
      title: 'Delete',
      width: '50px'
    },
    {
      title: 'Gid',
      key: 'Gid',
      filterType: 'input'
    },
    {
      title: 'Source Org Code',
      key: 'SourceOrgCode',
      filterType: 'input'
    },
    {
      title: 'Target Org Code',
      key: 'TargetOrgCode',
      filterType: 'input'
    },
    {
      title: 'Origin',
      key: 'Origin',
      filterType: 'dropdown',
      values: Object.values(TransferListEntryOrigin)
    },
    {
      title: 'Created at',
      key: 'CreatedAt',
      filterType: 'date'
    },
    {
      title: 'Created by Gid',
      key: 'CreatedByGid',
      filterType: 'input'
    },
    {
      title: 'Last modified at',
      key: 'LastModifiedAt',
      filterType: 'date'
    },
    {
      title: 'Last modified by Gid',
      key: 'LastModifiedByGid',
      filterType: 'input'
    }
  ];

  constructor(
    public transferListService: TransferListService,
    private route: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog
  ) {}

  openCreateTransferListEntryPopup(): void {
    this.matDialog.open(TransferListEntryPopupComponent, {
      data: { action: PopupAction.New, item: {} }
    });
    error => {
      this.matDialog.closeAll();
    };
  }

  openUpdateTransferListEntryPopup(item: TransferListEntryDto): void {
    this.matDialog.open(TransferListEntryPopupComponent, {
      data: { action: PopupAction.Edit, item: item }
    });
    error => {
      this.matDialog.closeAll();
    };
  }

  openDeleteTransferListEntryPopup(item: TransferListEntryDto): void {
    this.matDialog.open(TransferListEntryPopupComponent, {
      data: {
        action: PopupAction.Delete,
        item: item
      }
    });
    error => {
      this.matDialog.closeAll();
    };
  }
}
