import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StructureListEntryDto, UpdateStructureListEntryDto } from '@coin/importer/dto/util';
import { Subject, Subscription } from 'rxjs';
import { PopupAction } from '@coin/importer/common/util';
import { StructureListService } from '../../../services/organisation-management/structure-list.service';
import { ToastService } from '../../../services/toast/toast.service';

@Component({
  selector: 'ci-structure-list-entry-popup',
  templateUrl: './structure-list-entry-popup.component.html',
  styleUrls: ['./structure-list-entry-popup.component.scss'],
  standalone: false
})
export class StructureListEntryPopupComponent implements OnInit, OnDestroy {
  Action = PopupAction;
  originalItem = this.data['item'] as StructureListEntryDto;
  action = this.data['action'] as PopupAction;
  acting: string[] = ['X', ''];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any;
  uploading = false;
  uploadSubscription: Subscription;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public matDialogRef: MatDialogRef<StructureListEntryPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private toast: ToastService,
    public structureListService: StructureListService
  ) {}

  ngOnInit(): void {
    if (this.data['action'] === PopupAction.New) {
      this.item = {
        TargetOrgCode: '',
        Position: '',
        HeadGid: '',
        Acting: ''
      };
    } else {
      this.item = this.originalItem;
    }
  }

  async create() {
    if (this.validate()) {
      this.trimStringFields();
      await this.structureListService.createEntry(this.item);
    }
    this.structureListService.setTemporaryLog(this.item);
    this.close();
  }

  async update() {
    if (this.validate()) {
      this.trimStringFields();
      this.item = this.item.trim();
      await this.structureListService.updateEntry(this.item);
    }
    this.close();
  }

  async delete() {
    if (this.validate()) {
      await this.structureListService.deleteEntry(this.item.TargetOrgCode);
    }
    this.close();
    window.location.reload();
  }

  close() {
    if (this.uploading) {
      this.cancelUpload();
    } else {
      this.matDialogRef.close();
    }
  }

  private cancelUpload() {
    this.uploadSubscription?.unsubscribe();
    this.uploading = false;
    this.toast.info('Upload cancelled');
  }

  disableComment(): string {
    return ` (no previous uploads)`;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  validate(): boolean {
    for (const [k] of Object.entries(this.item)) {
      if (!this.item[k]) {
        if (k === 'Acting') {
          if (this.item[k] === '') {
            return true;
          }
        }
        return false;
      }
    }
    return true;
  }

  validateString(str: string): string {
    if (!str) {
      return 'cannot be empty';
    }
    if (str && !str.match(/([A-Za-z0-9]+( [A-Za-z0-9]+)?)/i)) {
      return 'has invalid characters';
    }
    return;
  }

  trimStringFields() {
    this.item.TargetOrgCode = this.item.TargetOrgCode.trim();
    this.item.HeadGid = this.item.HeadGid.trim();
  }
}
