import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-turbine',
  templateUrl: './turbine.component.html',
  styleUrls: ['./turbine.component.scss'],
  animations: [
    trigger('shrinkExpand', [
      transition(':enter', [
        style({ height: '0px', minWidth: '0px', maxWidth: '0px', opacity: 0 }),
        animate('350ms cubic-bezier(0.1, -0.6, 0.2, 0)', style({ height: '*', minWidth: '*', maxWidth: '*', opacity: 1 }))
      ]),
      transition(':leave', [animate('350ms cubic-bezier(0.1, -0.6, 0.2, 0)', style({ height: '0px', minWidth: '0px', maxWidth: '0px', opacity: 0 }))])
    ])
  ],
  standalone: false
})
export class TurbineComponent implements OnInit {
  @Input() speed = '';
  @Input() contrast = false;
  @Input() withPole = true;
  @Input() shadow = true;
  @Input() text = false;

  currLoadingNum = 0;
  reload = false;

  ngOnInit() {
    if (this.text) {
      this.updateLoadingNum();
    }
  }

  updateLoadingNum() {
    setTimeout(() => {
      if (this.currLoadingNum < 5) {
        this.reload = true;
        setTimeout(() => {
          this.currLoadingNum += 1;
          this.reload = false;
          this.updateLoadingNum();
        }, 350);
      }
    }, 4000);
  }
}
