import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'ci-auth-gms-angular-client',
  template: '',
  styles: [],
  standalone: false
})
export class AuthGmsClientCallbackComponent implements OnInit {
  constructor(private auth: AuthService) {}

  ngOnInit() {
    this.auth.handleLoginCallback();
  }
}
