import { MatDialog } from '@angular/material/dialog';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DecisionDto, LogDto, State, StateUtils } from '@coin/importer/dto/util';

import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { environment } from '@coin/shared/util-environments';
import { SimpleTableComponent, SimpleTableHeader } from '../../components/simple-table/simple-table.component';
import { UploadPopupComponent } from '../../components/upload-popup/upload-popup.component';

import { LogsService } from '../../services/logs/logs.service';
import { ToastService } from '../../services/toast/toast.service';
import { FileTypeService } from '../../services/file-type/file-type.service';
import { SkipPreviousImportsPopupComponent } from '../../components/skip-previous-imports-popup/skip-previous-imports-popup.component';

@Component({
  selector: 'ci-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: false
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(SimpleTableComponent) simpleTable: SimpleTableComponent;

  appliedFilters = {};
  public queryParameters$: Observable<Params> = this.route.queryParamMap;
  checkedItems = [];
  private unsubscribe$ = new Subject<void>(); // TODO Refactor
  private resetSubription: Subscription;
  public isExpectedToChange = StateUtils.isExpectedToChange;
  public updaterIntervalInMs = environment.subscriptionIntervalInMs;
  public selectedTableHeaders: SimpleTableHeader[] = [
    {
      title: '',
      key: '',
      width: '50px'
    },
    {
      title: 'File Name',
      key: 'fileName',
      filterType: 'input',
      width: '3fr'
    },
    {
      title: 'Process Step',
      key: 'state',
      filterType: 'dropdown',
      values: Object.values(State),
      width: '3fr'
    },
    {
      title: 'Uploaded At',
      key: 'uploadedAt',
      filterType: 'date',
      width: '3fr'
    },
    {
      title: 'File Type',
      key: 'fileType',
      filterType: 'input',
      width: '2fr'
    },
    {
      title: 'File Size',
      key: 'uploadSizeInBytes',
      width: '2fr'
    },
    {
      title: 'Delta Size',
      width: '3fr'
    },
    {
      title: 'Invalid Datasets',
      width: '3fr'
    },
    {
      title: 'Decided By',
      width: '3fr'
    }
  ];

  constructor(
    public selectedTableService: LogsService,
    private router: Router,
    private _matDialog: MatDialog,
    private route: ActivatedRoute,
    private logsService: LogsService,
    private toast: ToastService,
    private fileTypeService: FileTypeService
  ) {}

  async ngOnInit() {
    if (!this.fileTypeService.fileTypeSubject$.getValue()?.length) {
      await this.fileTypeService.loadFileTypesToSubject();
    }
  }

  ngAfterViewInit(): void {
    this.resetSubription = this.logsService.resetter$.pipe(takeUntil(this.unsubscribe$)).subscribe(isReset => {
      if (isReset) {
        this.simpleTable.fullReset();
      }
    });
  }

  loadSummary(element: LogDto) {
    this.router.navigate([`summary/${element.id}`]);
  }

  isStaging(element: LogDto): boolean {
    return element.state === State.Staging;
  }

  isPending(element: LogDto): boolean {
    return element.state === State.Pending;
  }

  isNotReadyState(element: LogDto): boolean {
    return this.isPending(element) && this.isStaging(element);
  }

  isPostStaging(element: LogDto): boolean {
    return element.state !== State.Pending && element.state !== State.Staging;
  }

  openJumpPopup(element: LogDto) {
    const popup = this._matDialog.open(SkipPreviousImportsPopupComponent, {
      disableClose: true,
      data: { element }
    });
    error => {
      this.toast.error('Unknown Error');
      console.log('Unknown Error: ', error);
      this._matDialog.closeAll();
    };

    popup
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(reload => {
        if (reload) {
          this.simpleTable.triggerReload();
        }
      });
  }

  openUploadPopup(): void {
    const popup = this._matDialog.open(UploadPopupComponent, {
      disableClose: true
    });
    error => {
      this.toast.error('Error while loading FileTypes');
      console.log('Error while loading FileTypes: ', error);
      this._matDialog.closeAll();
    };
  }

  ngOnDestroy() {
    this.resetSubription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openSettingsPage() {
    this.router.navigate(['settings']);
  }

  getProgressPercentage(message: string): number {
    const arr = message.split('/');
    const percent = parseInt(arr[0]) * (100 / parseInt(arr[1]));
    return Math.round(percent);
  }

  getNameFromDecision(decision: DecisionDto): string {
    if (decision?.decidedBy?.firstname && decision?.decidedBy?.lastname) {
      return `${decision.decidedBy.firstname} ${decision.decidedBy.lastname}`;
    }
    if (decision?.decidedBy?.lastname) {
      return `${decision.decidedBy.lastname}`;
    }
    return 'N/A';
  }
}
