import { environment } from '@coin/shared/util-environments';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { BaseRepublishDto, CoinTransactionState, CompositeTransactionDto, ITransactionState, LogDto } from '@coin/importer/dto/util';
import { Subject, Subscription, interval } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { LogsService } from '../../services/logs/logs.service';

@Component({
  selector: 'ci-transaction-state-progress-bar',
  templateUrl: './transaction-state-progress-bar.component.html',
  styleUrls: ['./transaction-state-progress-bar.component.scss'],
  standalone: false
})
export class TransactionStateProgressBarComponent implements OnInit, OnDestroy {
  @Input()
  public data: LogDto | BaseRepublishDto;

  public compositeTransaction: CompositeTransactionDto;
  public transactionStates: ITransactionState[];

  transactionStateSubscription: Subscription;

  public mode: 'determinate' | 'indeterminate' = 'indeterminate';
  public percentage = 100;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private logsService: LogsService) {}

  ngOnInit(): void {
    this.subscribeTransactionStateUpdates();
  }

  subscribeTransactionStateUpdates(): void {
    this.transactionStateSubscription = interval(environment.subscriptionIntervalInMs)
      .pipe(
        switchMap(() => this.logsService.getCompositeTransactionById(this.data.id)),
        takeUntil(this.destroy$)
      )
      .subscribe(result => {
        if (result) {
          const coiniTransactionState = result.SubTransactions.filter(transaction => transaction.TransactionId.endsWith(this.data.id)).pop();
          this.transactionStates = result.SubTransactions;
          if (coiniTransactionState.State !== CoinTransactionState.Started) {
            this.mode = 'determinate';
          }
        }
      });
  }

  calculateTransactionProcessPercentage(transactionState: ITransactionState): number {
    if (transactionState) {
      if (transactionState.ProgressCount && transactionState.TotalCount) {
        return (transactionState.ProgressCount / transactionState.TotalCount) * 100;
      }
    }
    return 0;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
