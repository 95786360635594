import { Component, Input } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { LogDto } from '@coin/importer/dto/util';
import { LogsService } from '../../services/logs/logs.service';
import { ToastService } from '../../services/toast/toast.service';
import { GeneralUtilities } from '../../utility/general-utilities';

@Component({
  selector: 'ci-import-details',
  templateUrl: './import-details.component.html',
  styleUrls: ['./import-details.component.scss'],
  standalone: false
})
export class ImportDetailsComponent {
  @Input()
  public data: LogDto;
  isLoading = false;
  private destroy$ = new Subject<boolean>();

  constructor(
    private logService: LogsService,
    private toast: ToastService
  ) {}

  downloadFile(fileName: string): void {
    this.isLoading = true;
    this.toast.info('Download started...');
    this.logService
      .getPresignedDownloadUrl(this.data.id, fileName, 'csv')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        obj => {
          GeneralUtilities.downloadFile(obj, this.data.stats[fileName], 'csv');
          this.toast.success('Download successful.');
          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
          this.toast.error('Download stopped with error');
          console.log('Download finished with error: ', error);
        }
      );
  }
}
