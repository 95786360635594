import { Pipe, PipeTransform } from '@angular/core';

const UNITS = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

@Pipe({
  name: 'fileSize',
  standalone: false
})
export class FileSizePipe implements PipeTransform {
  transform(value: string | number = ''): string {
    const bytes = parseInt(value?.toString(), 10);

    if (isNaN(bytes) || bytes < 0) return '';
    if (bytes === 0) return `0 ${UNITS[0]}`;

    const unit = Math.floor(Math.log(bytes) / Math.log(1024));
    const result = parseFloat((bytes / Math.pow(1024, unit)).toFixed(2));

    return `${result} ${UNITS[unit]}`;
  }
}
