import { Component, Input, OnInit } from '@angular/core';
import { DecisionDto, LogDto } from '@coin/importer/dto/util';

@Component({
  selector: 'ci-import-decision',
  templateUrl: './import-decision.component.html',
  styleUrls: ['./import-decision.component.scss'],
  standalone: false
})
export class ImportDecisionComponent implements OnInit {
  @Input()
  public data: LogDto;
  public decision: DecisionDto;

  constructor() {}

  ngOnInit(): void {
    this.decision = this.data?.decision;
  }
}
