import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileFormat, FileTypeDto } from '@coin/importer/dto/util';
import { PopupAction } from '@coin/importer/common/util';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ThemePalette } from '@angular/material/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { Subject, Subscription } from 'rxjs';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { ToastService } from '../../services/toast/toast.service';
import { UploadService } from '../../services/upload/upload.service';
import { GeneralUtilities } from '../../utility/general-utilities';
import { FileTypeService } from '../../services/file-type/file-type.service';

@Component({
  selector: 'ci-file-type-popup',
  templateUrl: './file-type-popup.component.html',
  styleUrls: ['./file-type-popup.component.scss'],
  standalone: false
})
export class FileTypePopupComponent implements OnInit, OnDestroy {
  constructor(
    public matDialogRef: MatDialogRef<FileTypePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fileTypeService: FileTypeService,
    private toast: ToastService,
    private readonly uploadService: UploadService
  ) {}

  Action = PopupAction;
  originalItem = this.data['item'] as FileTypeDto;
  action = this.data['action'] as PopupAction;
  item: FileTypeDto;

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  public FORMAT: string[] = Object.values(FileFormat);

  disableThreshold: boolean;
  disableDelimiter: boolean;
  disableEntityWrapper: boolean;

  slackNotification: boolean;

  thresholdCheckBoxState = true;
  slackNotificationCheckBoxState = true;

  schemaSourceFile: File;
  schemaSourceFileName: string;
  uploading = false;
  progressBarColor: ThemePalette = 'primary';
  progressMode: ProgressBarMode = 'determinate';
  progressValue = 0;
  uploadSubscription: Subscription;
  validExportAttributes = false;
  requiredExportAttributes = false;
  emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/;

  temporaryThreshold: string;
  temporaryRetention: string;

  private destroy$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.item = GeneralUtilities.clone(this.originalItem);

    // Set default value to JSON when creating new file type
    if (this.action === PopupAction.New) {
      this.item.fileFormat = FileFormat.JSON;
      this.disableDelimiter = true;
    }

    if (this.item.threshold == null) {
      this.disableThreshold = true;
      this.thresholdCheckBoxState = false;
    } else {
      this.temporaryThreshold = String(this.item.threshold);
    }

    this.item.slackNotification ? (this.slackNotificationCheckBoxState = true) : (this.slackNotificationCheckBoxState = false);

    this.temporaryRetention = this.item.retentionTime == null ? '0' : String(this.item.retentionTime);

    if (this.item.fileFormat === FileFormat.CSV) {
      this.disableDelimiter = false;
    }

    if (this.item.fileFormat === FileFormat.JSON) {
      this.disableEntityWrapper = false;
    }
  }

  close() {
    if (this.uploading) {
      this.cancelUpload();
    } else {
      this.matDialogRef.close();
    }
  }

  create() {
    this.uploading = true;
    this.uploadSubscription = this.uploadService
      .uploadSchemaSourceFileViaPresignedUrl(this.item.prefix, this.schemaSourceFile)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        this.handleUploading.bind(this),
        error => {
          console.log(error);
        },
        () => {
          this.uploading = false;
        }
      );
  }

  checkIfValid(): boolean {
    return (
      this.validateRequired() &&
      (this.action === PopupAction.Edit || this.validExportAttributes) &&
      !this.validateName() &&
      !this.validatePrefix() &&
      !this.validateIdentifier() &&
      !this.validateThreshold() &&
      !this.validateDelimiter() &&
      !this.validateEntityWrapper() &&
      !this.validateRetention()
    );
  }

  async delete() {
    this.uploading = true;
    const isDeleted = await this.fileTypeService.deleteFileType(this.item);
    if (isDeleted) {
      this.matDialogRef.close();
    } else {
      this.uploading = false;
      this.toast.error('Could not write to Database');
    }
  }

  checkThresholdBox(event) {
    if (event) {
      this.disableThreshold = false;
    } else {
      this.disableThreshold = true;
      this.item.threshold = null;
    }
  }

  checkSlackNotificationBox(event) {
    if (event) {
      this.slackNotification = true;
      this.item.slackNotification = true;
    } else {
      this.slackNotification = false;
      this.item.slackNotification = false;
    }
  }

  onFileFormatSelect(event) {
    this.item.fileFormat = event.value;
    if (event.value === FileFormat.CSV) {
      this.disableDelimiter = false;
      this.disableEntityWrapper = true;
      this.item.entityWrapper = null;
    } else if (event.value === FileFormat.JSON) {
      this.disableDelimiter = true;
      this.disableEntityWrapper = false;
      this.item.delimiter = null;
    }
  }

  onFileSelected(event: Event) {
    const element = event.target as HTMLInputElement;
    if (element) {
      // eslint-disable-next-line prefer-destructuring
      this.schemaSourceFile = element.files[0];
      this.schemaSourceFileName = this.schemaSourceFile.name;
    }
  }

  async save() {
    this.uploading = true;
    this.originalItem = { ...this.item };
    const result = await this.fileTypeService.updateFileTypeProperties(this.item);
    if (result) {
      this.matDialogRef.close();
    } else {
      this.uploading = false;
      this.toast.error('Could not write to Database');
    }
  }

  private cancelUpload() {
    this.uploadSubscription?.unsubscribe();
    this.schemaSourceFile = null;
    this.schemaSourceFileName = null;
    this.progressValue = 0;
    this.uploading = false;
    this.toast.info('Upload cancelled');
  }

  private async handleUploading(event: HttpEvent<unknown>) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        this.progressValue = Math.round((event.loaded / event.total) * 100);
        break;
      case HttpEventType.Response: {
        this.progressValue = 100;
        const isCreated = await this.fileTypeService.createFileType(this.item);
        if (isCreated) {
          this.matDialogRef.close();
        } else {
          this.toast.error('Could not write to Database');
        }
        this.uploading = false;

        break;
      }
    }
  }

  validateRequired(): boolean {
    return (
      (this.action === PopupAction.Edit || this.requiredExportAttributes) &&
      !!this.item?.displayedName &&
      !!this.item?.prefix &&
      !!this.item?.identifier &&
      !!this.item?.fileFormat &&
      this.item?.retentionTime != null &&
      (!!this.schemaSourceFileName || !!this.item?.schema)
    );
  }

  validateName(): string {
    if (this.item.displayedName && !this.item.displayedName.match(/^[a-zA-Z0-9 _-]+$/)) {
      return 'has invalid characters';
    }
    return;
  }

  validatePrefix(): string {
    if (this.item.prefix && !this.item.prefix.match(/^[a-zA-Z0-9_-]+$/)) {
      return 'has invalid characters';
    }
    return;
  }

  validateIdentifier(): string {
    if (this.item.identifier && !this.item.identifier.match(/^[a-zA-Z0-9.,_-]+$/)) {
      return 'has invalid characters';
    }
    return;
  }

  validateThreshold(): string {
    //   if (
    //     this.temporaryThreshold == null ||
    //     !this.temporaryThreshold.length ||
    //     this.thresholdCheckBoxState
    //   ) {
    //     this.item.threshold = null;
    //     return;
    //  }
    const convertedToNumber = Number(this.temporaryThreshold);
    if (isNaN(convertedToNumber)) {
      return 'must be a number';
    }
    if (convertedToNumber > 100 || convertedToNumber < 0) {
      return 'out of range';
    }
    this.item.threshold = convertedToNumber;
    console.log(convertedToNumber);
    console.log(typeof convertedToNumber);
    return;
  }

  validateDelimiter(): string {
    if (this.item.delimiter && !this.item.delimiter.match(/^[a-zA-Z0-9|.,!#_-]+$/)) {
      return 'has invalid characters';
    }
    return;
  }

  validateEntityWrapper(): string {
    if (this.item.entityWrapper && !this.item.entityWrapper.match(/^[a-zA-Z0-9_-]+$/)) {
      return 'has invalid characters';
    }
    return;
  }

  validateRetention(): string {
    const convertedToNumber = Number(this.temporaryRetention);
    if (isNaN(convertedToNumber)) {
      return 'must be a number';
    }

    if (convertedToNumber < 0) {
      return 'out of range';
    }

    this.item.retentionTime = convertedToNumber;
    return;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
