// import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { TinyHelpers } from '../../helpers/tiny-helpers';

interface DialogData {
  translate?: boolean;
  headline?: string;
  headlineData?: string;
  msg?: string;
  msgValue?: string;
  cancelMsg?: string;
  confirmMsg?: string;
  provideInput?: boolean;
  inputMandatory?: boolean;
  provideDropdownValue?: boolean;
  dropdownLabel?: string;
  currentDropdownValue?: string;
  dropdownValues?: string[];
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  standalone: false
})
export class ConfirmationDialogComponent implements OnInit {
  providedInput = '';
  selectedDropdownValue: string;

  getTranslatedValue(text: string): string {
    // const translatedText = text ? this.translateService.instant(text || '') : '';
    const translatedText = text || '';
    const containsMsgValue = translatedText.includes('{msgValue}');
    return containsMsgValue ? translatedText.replace('{msgValue}', this.data?.msgValue?.toString()) : translatedText;
  }

  getHeadlineData(): string {
    return this.data?.headlineData ? `- ${this.data?.headlineData}` : '';
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    // private translateService: TranslateService,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {}

  ngOnInit() {
    this.dialogRef.addPanelClass('d1r-dialog-container');
    this.selectedDropdownValue = this?.data?.currentDropdownValue || '';
  }

  dropdownDisplayFn(text: string): string {
    return TinyHelpers.pascalcaseToText(text);
  }

  cancel() {
    this.dialogRef.close();
  }

  confirm() {
    this.dialogRef.close({
      input: this.providedInput,
      dropdownValue: this.selectedDropdownValue
    });
  }
}
