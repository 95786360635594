import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StatsDto } from '@coin/importer/dto/util';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseChartDirective } from 'ng2-charts';
import { Chart } from 'chart.js';
import { FileTypeService } from '../../services/file-type/file-type.service';
import { DatasetDoughnut } from './dataset-doughnut';
import { Doughnut } from './doughnut';
import { FileTypeDoughnut } from './fileType-doughnut';
import { ValidationsDoughnut } from './validations-doughnut';
import { emptyDoughnutPlugin } from './empty-doughnut-plugin/empty-doughnut-plugin';

@Component({
  selector: 'ci-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
  standalone: false
})
export class ChartsComponent implements OnInit, OnDestroy {
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  @Input() public importStats: StatsDto | undefined;
  @Input() public level: 'datasets' | 'changes' | 'fileTypes';
  public chartData: Doughnut;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private fileTypeService: FileTypeService) {}

  ngOnInit() {
    Chart.register(emptyDoughnutPlugin);

    switch (this.level) {
      case 'datasets':
        this.chartData = new DatasetDoughnut(this.importStats);
        break;
      case 'changes':
        this.chartData = new ValidationsDoughnut(this.importStats?.validations);
        break;
      case 'fileTypes':
        this.fileTypeService.fileTypeSubject$.pipe(takeUntil(this.destroy$)).subscribe(data => {
          this.chartData = new FileTypeDoughnut(data);
        });
        break;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
