import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionHelper } from '@coin/importer/common/ui';
import { Store } from '@ngxs/store';
import { filter } from 'rxjs/operators';
import { CoinUser } from '../../auth/models/user-auth.model';
import { AuthService } from '../../auth/services/auth.service';
import { TokenService } from '../../auth/services/token.service';
import { UserState } from '../../auth/store';

@Component({
  selector: 'ci-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  animations: [
    trigger('shrinkExpand', [
      transition(':enter', [
        style({
          height: '0px',
          minHeight: '0px',
          maxHeight: '0px',
          opacity: 0
        }),
        animate('650ms ease-in-out', style({ height: '*', minHeight: '*', maxHeight: '*', opacity: 1 }))
      ]),
      transition(':leave', [
        animate(
          '650ms ease-in-out',
          style({
            height: '0px',
            minHeight: '0px',
            maxHeight: '0px',
            opacity: 0
          })
        )
      ])
    ]),
    trigger('slide', [
      transition(':enter', [style({ transform: 'translateX(-100%)' }), animate('200ms ease-in', style({ transform: 'translateX(0%)' }))]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateX(-100%)', opacity: 0 }))])
    ]),
    trigger('fade', [
      transition(':enter', [style({ opacity: 0 }), animate('220ms ease-in', style({ opacity: 1 }))]),
      transition(':leave', [animate('150ms ease-in', style({ opacity: 0 }))])
    ])
  ],
  standalone: false
})

// TODO Refactor SubscriptionHelper
export class UserMenuComponent extends SubscriptionHelper implements OnInit {
  user: CoinUser;
  codes: string;
  userImageSrc: string = '';

  menuItems = [];
  showMenu = false;
  selectableLangs = [];
  languageSelected = 'English';
  lightDarkMode = true;

  remainingTimeHovered = false;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      if (this.showMenu) {
        this.openMenu();
      }
    }
  }

  constructor(
    private eRef: ElementRef,
    private router: Router,
    public token: TokenService,
    private store: Store,
    private authService: AuthService
  ) {
    super();
    this.setupMenuItems();
  }

  async ngOnInit() {
    this.listenToLoggedInUser();
    this.token.startCheckingTimeTillLogout();
  }

  private listenToLoggedInUser(): void {
    this.subscribe(this.store.select(UserState?.loggedInUser).pipe(filter(user => !!user)), user => {
      this.user = user;
    });
  }

  openMenu() {
    this.showMenu = !this.showMenu;
    this.openMenuItem();
  }

  openMenuItem(item?) {
    if (item && item.name === 'Logout') {
      this.user = undefined;
      this.router.navigate(['/logout']);
    }
  }

  setupMenuItems() {
    this.menuItems = [{ name: 'Logout', icon: 'login', submenuactive: false }];
  }

  onRightClick(e) {
    e.preventDefault();
  }

  remainingTimeHover(state: string) {
    this.remainingTimeHovered = state === 'enter';
  }

  renewToken(event) {
    event.stopPropagation();
    this.token.renewToken();
  }
}
