import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'chip-input',
  templateUrl: './chip-input.component.html',
  styleUrls: ['./chip-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class ChipInputComponent {
  @Input() loading: boolean = false;
  @Input() disabled: boolean = false;
  @Input() label: string = 'Chip';
  @Input() chips: string[] = [];
  @Input() placeholder: string = 'New chip...';
  @Input() regExp: RegExp;

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  constructor() {}

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  add(event: MatChipInputEvent): void {
    if (event.value) {
      if (!this.regExp || this.regExp.test(event.value)) {
        this.chips.push(event.value);
      }
      event.chipInput!.clear();
    }
  }

  remove(chip: string): void {
    const index = this.chips.indexOf(chip);

    if (index >= 0) {
      this.chips.splice(index, 1);
    }
  }

  resetFlags(): void {
    this.loading = false;
  }
}
