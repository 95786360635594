import { Component, OnInit } from '@angular/core';
import { FileTypeService } from './services/file-type/file-type.service';

@Component({
  selector: 'ci-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false
})
export class AppComponent {
  constructor(private fileTypeService: FileTypeService) {}
}
