import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseRepublishDto } from '@coin/importer/dto/util';
import { tap, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { RepublishService } from '../../services/republish/republish.service';

@Component({
  selector: 'ci-republish-summary',
  templateUrl: './republish_summary.component.html',
  styleUrls: ['./republish_summary.component.scss'],
  standalone: false
})
export class RepublishSummaryComponent implements OnInit, OnDestroy {
  public data: BaseRepublishDto;
  public isLoading: boolean;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private republishService: RepublishService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.route.params
      .pipe(
        tap(() => {
          this.isLoading = true;
        }),
        switchMap(params => this.republishService.getSingleImportLog(params.id)),
        takeUntil(this.destroy$)
      )
      .subscribe(obj => {
        this.data = obj;
        this.isLoading = false;
      });
  }

  formatErrorMessage(input: string | undefined): string {
    if (!input || typeof input !== 'string') return '';
    return input.split('jobRunId')[0].trim();
  }

  goBack() {
    this.router.navigate(['/republish']);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
