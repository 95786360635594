import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss'],
  standalone: false
})
export class LoadingScreenComponent {
  @Input() local?: boolean;
  @Input() contrast?: boolean;

  constructor() {}
}
