import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
  standalone: false
})
export class DurationFromMsPipe implements PipeTransform {
  transform(value: string | number): string {
    const ms = parseInt(value?.toString(), 10);
    if (isNaN(ms) || ms < 0) return '';
    if (ms === 0) return '0ms';
    if (ms < 1000) return `${ms}ms`;

    let result = '';

    const hours = Math.floor(ms / (1000 * 60 * 60));
    let remaining = ms % (1000 * 60 * 60);
    if (hours) {
      result = `${hours}h `;
    }

    const minutes = Math.floor(remaining / (1000 * 60));
    remaining = remaining % (1000 * 60);
    if ((result && remaining) || minutes) {
      result += `${minutes}min `;
    }

    const seconds = Math.floor(remaining / 1000);
    remaining = remaining % 1000;
    if ((result && remaining) || seconds) {
      result += `${seconds}s `;
    }

    if (remaining) {
      result += `${remaining}ms `;
    }

    return result.trim();
  }
}
