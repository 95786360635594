import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LogDto } from '@coin/importer/dto/util';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LogsService } from '../../services/logs/logs.service';
import { ToastService } from '../../services/toast/toast.service';

@Component({
  selector: 'ci-skip-previous-imports-popup',
  templateUrl: './skip-previous-imports-popup.component.html',
  styleUrls: ['./skip-previous-imports-popup.component.scss'],
  standalone: false
})
export class SkipPreviousImportsPopupComponent implements OnInit, OnDestroy {
  loadingPopupData: boolean;
  showConfirmation: boolean;
  currentLog: LogDto;
  logsAheadInQueue: LogDto[] = [];
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public matDialogRef: MatDialogRef<SkipPreviousImportsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly logService: LogsService,
    private readonly toast: ToastService
  ) {}

  ngOnInit(): void {
    this.showConfirmation = false;
    this.currentLog = this.data.element;
    if (this.currentLog) {
      if (this.currentLog.fileType?.threshold) {
        this.toast.warn('Uploads of this file type are handled by an automatic decision process. Manual interference is not possible.');
        this.close();
      }

      this.loadingPopupData = true;
      this.logService
        .getPreviousQueuedLogs(this.currentLog.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          logs => {
            this.logsAheadInQueue = logs;
          },
          null,
          () => {
            this.loadingPopupData = false;
          }
        );
    } else {
      this.toast.error('could not find any data');
      this.close();
    }
  }

  close(reload = false) {
    this.loadingPopupData = false;
    this.matDialogRef.close(reload);
  }

  submit() {
    this.loadingPopupData = true;
    if (this.currentLog) {
      this.logService
        .rejectPreviousQueuedLogs(this.currentLog.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          response => {
            if (response.length) {
              this.toast.success(`${response.length} previous queued imports have been rejected - STAGING of current job has started.`);
              this.close(true);
            } else {
              this.toast.warn('no Jobs have been updated');
              this.close();
            }
          },
          response => {
            this.toast.error(response.error?.message || 'unknown Error');
            this.close();
          }
        );
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
