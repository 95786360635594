/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines */
import { CdkDrag, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, ContentChild, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ConfirmationDialogComponent, TinyHelpers } from '@coin/importer/common/ui';
import { BehaviorSubject, combineLatest, interval, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, finalize, switchMap, takeUntil, takeWhile, tap, withLatestFrom } from 'rxjs/operators';
import { IPaginatedList } from '@coin/importer/dto/util';
import { Params } from '@angular/router';
import { environment } from '@coin/shared/util-environments';
import { PaginatedResult } from '@coin/shared/util-models';
import { ToastService } from '../../services/toast/toast.service';
import { shrinkExpandAnimation } from '../../animations/shrinkExpand.anim';
import { shrinkExpandAllAnimation } from '../../animations/shrinkExpandAll.anim';

export enum SimpleTableGroupState {
  Visible = 'visible',
  Inactive = 'inactive',
  Hidden = 'hidden'
}

export type AcceptedParams = {
  page?: number;
  limit?: number;
  search?: string;
  filter?: string;
  sort?: string;
};

export interface SimpleTableService {
  queryPagedAndFilteredData: (params: AcceptedParams) => Observable<any>;
  checkProgressUpdate?: (ids: unknown[]) => Observable<any[]>;
  getFieldValues?: (page: number, size: number, targetProperty: string, seasonId?: string, searchText?: string) => Observable<IPaginatedList<any>>;

  simulationId?: string;
  resetter$?: Observable<unknown>;
  newEntry$?: Observable<any>;
}

export interface SimpleTableHeader {
  title: string;
  width?: string;
  icon?: string;
  hide?: boolean;
  hideSorting?: boolean;
  hideFilter?: boolean;
  key?: string;
  checkAll?: boolean;
  matchItems?: Array<any>;
  special?: 'name' | 'label' | 'date' | 'role' | 'match' | 'number' | string;
  filterType?: 'date' | 'dropdown' | 'input' | 'custom' | 'customDropdown';
  filterTooltip?: string;
  customFilterConfig?: SimpleTableCustomFilter;
  values?: any;
  async?: boolean;
  overlay?: boolean;
  overlayStart?: boolean;
  overlayEnd?: boolean;
  overlayContrast?: boolean;
  group?: string;
}

export interface SimpleTableCustomFilter {
  filterClick?: (instance: SimpleTableComponent) => any;
  filterFunction?: (instance: SimpleTableComponent) => any;
}

@Component({
  selector: 'ci-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss'],
  animations: [shrinkExpandAnimation, shrinkExpandAllAnimation],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
  standalone: false
})
export class SimpleTableComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() header: SimpleTableHeader[] | string[] = [];
  @Input() externalDataInput$: Subject<any[]>;
  @Input() externalDataList: any[];
  @Input() service: SimpleTableService;
  @Input() withSearch = false;
  @Input() hidePagination = false;
  @Input() draggable = false;
  @Input() serverside = false;
  @Input() serverPaginated = false;
  @Input() filterPrefix = '';
  @Input() sortingPrefix = '';
  @Input() defaultFilter = '';
  @Input() updaterIntervalInMs = 5000;
  @Input() dragAndDrop = false;
  @Input() pageSize = environment.importerTableLimit;
  @Input() pageIndex = 0;
  @Input() presetQueryParams: Params;
  @Input() isExpectedToChange?: (entry: unknown) => boolean;
  @Input() lazyLoadFn?: (page: number, search: string, key: string) => Observable<PaginatedResult<string>>;
  @Input() defaultInactiveGroups: string[];
  @Input() loading = false;

  @Output() checkAllToggleChange: EventEmitter<any> = new EventEmitter();
  @Output() itemDrop: EventEmitter<any> = new EventEmitter();
  @Output() reload: EventEmitter<void> = new EventEmitter();

  @ContentChild('columns') columnsRef: TemplateRef<any>;
  @ContentChild('buttons') buttonsRef: TemplateRef<any>;
  @ContentChild('noData') noDataRef: TemplateRef<any>;

  public today = new Date().toISOString();
  public inactiveGroups: string[] = [];
  public inactiveColumns$: BehaviorSubject<SimpleTableGroupState[]> = new BehaviorSubject([]);

  showFilter = '';

  public data = [];
  originalData = [];
  public templateColumns: string;

  private isFirstLoad = true;

  @ViewChild('paginator', { static: false }) paginator: MatPaginator;
  public length = environment.importerTableLimit;
  public pageSizeOptions: number[] = [5, 10, 25, 50, 100, 200];

  private pageChanged$: BehaviorSubject<PageEvent> = new BehaviorSubject(this.getInitPageEvent());
  public searchChanged$: BehaviorSubject<string> = new BehaviorSubject('');
  public filterChanged$: BehaviorSubject<string> = new BehaviorSubject('');
  public sortingChanged$: BehaviorSubject<string> = new BehaviorSubject('');
  private reloadTrigger$: BehaviorSubject<any> = new BehaviorSubject(null);
  private unsubscribe$ = new Subject<void>(); // TODO Refactor

  sortedItemsBy: any = {};
  filterVariables: any = {};

  private progressingEntities: any[] = [];
  private progressUpdater: Subscription;

  get headerObjects(): SimpleTableHeader[] {
    return this.isStringArray(this.header) ? this.header?.map(item => ({ title: item })) : this.header;
  }

  public get dataAvailable() {
    return this.data?.length;
  }

  public get count() {
    return this.length;
  }

  public isGroupStart(item: SimpleTableHeader, i: number) {
    return this.headerObjects[i - 1]?.group !== item.group && this.headerObjects[i + 1]?.group === item.group;
  }

  public isGroupEnd(item: SimpleTableHeader, i: number) {
    return this.headerObjects[i - 1]?.group === item.group && this.headerObjects[i + 1]?.group !== item.group;
  }

  public isItemInactive(group: string) {
    return this.inactiveGroups.includes(group);
  }

  constructor(
    private dialog: MatDialog,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.templateColumns = this.isStringArray(this.header)
      ? `repeat(${this.header.length}, minmax(0, 1fr))`
      : this.header.map(item => this.getGridColumnSize(item.width)).join(' ');

    if (this.externalDataInput$) {
      this.externalDataInput$.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
        this.data = data;
        this.originalData = data;
      });
    }

    if (this.externalDataList) {
      this.data = this.externalDataList;
    }

    if (this.defaultInactiveGroups) {
      this.setDefaultInactiveGroups();
    }

    this.service?.newEntry$?.pipe(delay(1000), takeUntil(this.unsubscribe$)).subscribe(element => {
      this.insertNewEntry(element);
      setTimeout(this.initiateProgressUpdater.bind(this), this.updaterIntervalInMs);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.externalDataList && changes.externalDataList) {
      this.data = changes.externalDataList.currentValue;
    }

    if (changes.service) {
      setTimeout(() => {
        this.cancelProgressUpdater();
        this.pageChanged$.next(this.getInitPageEvent());
      });
    }
  }

  ngAfterViewInit() {
    if (this.presetQueryParams && this.isFirstLoad) {
      this.loadQueryParams();
      this.isFirstLoad = false;
    }

    const debouncedSearchWithPaginatorReset$ = this.searchChanged$.pipe(
      debounceTime(400),
      tap(() => {
        if (!this.hidePagination && this.paginator) {
          this.paginator.pageIndex = this.pageIndex;
        }
        this.pageChanged$.next(this.getInitPageEvent());
      })
    );

    if (this.service) {
      combineLatest([this.pageChanged$, debouncedSearchWithPaginatorReset$, this.filterChanged$, this.sortingChanged$, this.reloadTrigger$])
        .pipe(
          debounceTime(500),
          tap(() => {
            this.loading = true;
          }),
          switchMap(([pageSettings, search, filter, sortText]) => {
            const URLParams: AcceptedParams = {
              page: pageSettings.pageIndex + 1,
              limit: pageSettings.pageSize,
              search: search,
              filter: filter,
              sort: sortText
            };

            return this.service.queryPagedAndFilteredData(URLParams);
          }),
          withLatestFrom(this.pageChanged$),
          finalize(() => {
            this.loading = false;
          }),
          takeUntil(this.unsubscribe$)
        )
        .subscribe(([result, pageSettings]) => {
          this.loading = false;
          if (!this.serverside && !this.serverPaginated) {
            this.setDataAndUpdatePaginator(result, pageSettings.pageSize, pageSettings.pageIndex);
          } else {
            this.data = result?.content;
            this.originalData = this.data ? TinyHelpers.deepCopy(this.data) : null;
            this.length = result?.total;
            this.toggleVisibleColumns();
            this.reload.emit();
            if (this.isExpectedToChange) {
              this.initiateProgressUpdater();
            }
          }
        });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.cancelProgressUpdater();
  }

  private updateProgressingList() {
    if (this.isExpectedToChange) {
      this.progressingEntities = this.data.filter(el => this.isExpectedToChange(el.state));
    }
  }

  private initiateProgressUpdater(): void {
    this.cancelProgressUpdater();
    this.updateProgressingList();
    if (!this.progressingEntities.length) return;
    this.progressUpdater = interval(this.updaterIntervalInMs)
      .pipe(
        // eslint-disable-next-line rxjs/no-ignored-takewhile-value
        takeWhile(() => !!this.progressingEntities.length),
        switchMap(() => this.service.checkProgressUpdate(this.getReducedProgressingEntities())),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        response => {
          this.updateTablewithProgress(response);
          this.updateProgressingList();
        },
        e => {
          this.toast.error('Could not retrieve updated list - please refresh page in one minute');
        }
      );
  }

  private getReducedProgressingEntities() {
    return this.progressingEntities.map(el => {
      if (el.id) {
        return { id: el.id };
      }
      return { fileName: el.fileName };
    });
  }

  private updateTablewithProgress(entries: any[]): void {
    // assume that each element has id-attribute to match by
    while (entries.length) {
      const element = entries.pop();
      let index = this.data.findIndex(obj => obj.id === element.id);
      if (index < 0) {
        index = this.data.findIndex(obj => obj.fileName === element.fileName);
      }
      if (index >= 0) {
        this.data[index] = element;
      }
    }
  }

  private cancelProgressUpdater(): void {
    this.progressUpdater?.unsubscribe();
  }

  private loadQueryParams() {
    for (const param of this.presetQueryParams.keys) {
      const content = this.presetQueryParams.getAll(param);
      if (typeof content !== 'string' && !Array.isArray(content)) {
        this.wrongParameter(param);
        continue;
      }
      switch (param) {
        case 'limit':
          this.presetLimit(content);
          break;
        case 'page':
          this.presetPage(content);
          break;
        case 'filter':
          this.presetFilters(content);
          break;
        case 'sort':
          this.presetSort(content);
          break;
        case 'search':
          this.presetSearch(content);
          break;
        default:
          this.wrongParameter();
          break;
      }
      this.applyFilter();
    }
  }

  private wrongParameter(parameter?: string) {
    if (parameter) {
      this.toast.info(`the parameter ${parameter.toString().toUpperCase()} does not match the required format.`);
    } else {
      this.toast.info('URL included incorrect parameters.');
    }
  }

  public fullReset() {
    this.showFilter = '';
    this.sortedItemsBy = {};
    this.filterVariables = {};
    this.pageSize = environment.importerTableLimit;
    this.pageIndex = 0;
    this.clearFilter();
    this.clearSorting();
    this.resetPage();
  }

  private presetLimit(parameter: string | string[]): void {
    const value = Array.isArray(parameter) ? parameter[0] : parameter;
    const num = parseInt(value);
    if (num > 0) {
      this.pageSize = num;
    }
  }

  private presetPage(parameter: string | string[]): void {
    const value = Array.isArray(parameter) ? parameter[0] : parameter;
    const num = parseInt(value);
    if (num > 0) {
      this.pageIndex = num - 1;
    }
  }

  private presetSort(parameter: string | string[]): void {
    let key;
    let value: string;
    if (Array.isArray(parameter)) {
      [key, value] = parameter[0].split('=');
      this.sortingChanged$.next(parameter[0]);
    } else {
      this.sortingChanged$.next(parameter);
      [key, value] = parameter.split('=');
    }
    this.sortedItemsBy.type = key;
    this.sortedItemsBy.direction = value;
  }

  private insertNewEntry(this: { data: any[]; paginator: MatPaginator }, entry: any): void {
    const newLength = this.data.unshift(entry);
    if (newLength > this.paginator.pageSize) {
      this.data.pop();
    }
  }

  private presetSearch(parameter: string | string[]): void {
    const value = Array.isArray(parameter) ? parameter[0] : parameter;
    this.onSearchChange(value);
  }

  private presetFilters(parameter: string | string[]): void {
    if (Array.isArray(parameter)) {
      parameter.forEach(element => {
        const [key, value] = element.split('=');

        if (!this.filterVariables[key]) {
          this.filterVariables[key] = [];
        }

        this.filterVariables[key].push(value);
      });
    } else {
      const [key, value] = parameter.split('=');
      this.filterVariables[key] = [value];
    }
  }

  setDefaultInactiveGroups() {
    this.inactiveGroups = [...this.defaultInactiveGroups];
    this.toggleVisibleColumns();
  }

  private isStringArray(header: SimpleTableHeader[] | string[]): header is string[] {
    return (header as string[])?.every(item => typeof item === 'string');
  }

  public toggleInactive(group: string) {
    if (this.inactiveGroups.includes(group)) {
      this.inactiveGroups = this.inactiveGroups.filter(item => item !== group);
    } else {
      this.inactiveGroups.push(group);
    }

    this.toggleVisibleColumns();
  }

  private toggleVisibleColumns() {
    const headerStates: SimpleTableGroupState[] = this.getHeaderGroupStates();
    this.inactiveColumns$.next(headerStates);
    this.templateColumns = (this.header as SimpleTableHeader[])
      ?.map((item, index) => {
        switch (headerStates[index]) {
          case SimpleTableGroupState.Visible:
            return this.getGridColumnSize(item.width);
          case SimpleTableGroupState.Inactive:
            return '120px';
          case SimpleTableGroupState.Hidden:
          default:
            return '';
        }
      })
      .join(' ');
  }

  private getHeaderGroupStates(): SimpleTableGroupState[] {
    return (this.header as SimpleTableHeader[])?.map((item, index) => {
      if (this.isItemInactive(item.group)) {
        return this.isGroupStart(item, index) ? SimpleTableGroupState.Inactive : SimpleTableGroupState.Hidden;
      }
      return SimpleTableGroupState.Visible;
    });
  }

  checkAllToggle(e) {
    this.checkAllToggleChange.emit({
      checked: e,
      items: this.data
    });
  }

  sortBy(item: SimpleTableHeader) {
    if (!this.serverside) {
      this.setSorting(item);
      if (this.sortedItemsBy.direction) {
        this.data = this.sortByKey(this.data, this.sortedItemsBy.type, item.special);
        if (this.sortedItemsBy.direction === 'ASC') {
          this.data = this.data.reverse();
        }
      } else {
        this.data = this.sortByKey(this.data, 'id');
      }
    } else {
      this.setSorting(item);
      const sortingText = Object.keys(this.sortedItemsBy)?.length ? `${this.sortingPrefix}${this.sortedItemsBy.type}=${this.sortedItemsBy.direction}` : '';
      this.sortingChanged$.next(sortingText);
    }
  }

  setSorting(item) {
    if (this.sortedItemsBy.type === item.key) {
      if (this.sortedItemsBy.direction === 'DESC') {
        this.sortedItemsBy = {};
      } else {
        this.sortedItemsBy.direction = 'DESC';
      }
    } else {
      this.sortedItemsBy = {
        type: item.key,
        direction: 'ASC'
      };
    }
  }

  clearFilter() {
    this.filterVariables = {};
    this.applyFilter();
  }

  clearFilterByKey(key: string) {
    const vars = Object.keys(this.filterVariables);
    for (let i = 0; i < vars.length; i++) {
      if (vars[i].includes(key)) {
        this.filterVariables[vars[i]] = null;
      }
    }
    this.applyFilter();
  }

  private clearSorting() {
    this.sortedItemsBy = {};
    this.sortingChanged$.next('');
  }

  private resetPage() {
    if (!this.hidePagination && this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.pageChanged$.next(this.getInitPageEvent());
  }

  sortByKey(array, key, special = '') {
    switch (special) {
      case 'name':
        return array.sort(this.sortByName(key));
      case 'othername':
        return array.sort(this.sortByName(key, true));
      case 'number':
        return array.sort(this.sortByNumber(key));
      case 'label':
        return array.sort(this.sortByLabel(key));
      case 'date':
        return array.sort(this.sortByDate(key));
      case 'role':
        return array.sort(this.sortByRole(key));
      case 'match':
        return array.sort(this.sortByMatch(key));
      default:
        return special.startsWith('path') ? array.sort(this.sortByPath(key, special)) : array.sort(this.defaultSort(key));
    }
  }

  private sortByName(key: string, other = false) {
    if (other) {
      return (a, b) => {
        const x = a[key].firstname?.toLowerCase() + a[key].lastname?.toLowerCase();
        const y = b[key].firstname?.toLowerCase() + b[key].lastname?.toLowerCase();
        return this.basicSort(x, y);
      };
    }
    return (a, b) => {
      const x = a[key].name.firstname.toLowerCase() + a[key].name.lastname.toLowerCase();
      const y = b[key].name.firstname.toLowerCase() + b[key].name.lastname.toLowerCase();
      return this.basicSort(x, y);
    };
  }

  private sortByNumber(key: string) {
    return (a, b) => a[key] - b[key];
  }

  private sortByMatch(key: string) {
    const { matchItems } = (this.header as any).find(itm => itm.key === key);
    return (a, b) => {
      const x = matchItems[a[key]];
      const y = matchItems[b[key]];
      return this.basicSort(x, y);
    };
  }

  private sortByLabel(key: string) {
    return (a, b) => {
      const x = this.getAllArrayItemsAsString(a[key], 'name');
      const y = this.getAllArrayItemsAsString(b[key], 'name');
      return this.basicSort(x, y);
    };
  }

  private sortByDate(key: string) {
    return (a, b) => {
      let x = new Date(a[key]).getTime();
      let y = new Date(b[key]).getTime();
      x = isNaN(x) ? 0 : x;
      y = isNaN(y) ? 0 : y;
      return this.basicSort(x, y);
    };
  }

  private sortByRole(key: string) {
    return (a, b) => {
      const aSort = a[key].sort(this.arraySortByKey('name'));
      const bSort = b[key].sort(this.arraySortByKey('name'));
      const x = this.getAllArrayItemsAsString(aSort, 'name');
      const y = this.getAllArrayItemsAsString(bSort, 'name');
      return this.basicSort(x, y);
    };
  }

  private arraySortByKey(key) {
    return (a, b) => this.basicSort(a[key], b[key]);
  }

  private sortByPath(key: string, special: string) {
    return (a, b) => {
      let x = a;
      let y = b;
      const path = special.split('|').slice(1);
      for (const itm of path) {
        x = x[itm] || '';
        y = y[itm] || '';
      }
      return this.basicSort(x, y);
    };
  }

  private defaultSort(key: string) {
    return (a, b) => this.basicSort(a[key]?.toLowerCase(), b[key]?.toLowerCase());
  }

  private basicSort(x: any, y: any) {
    return x < y ? -1 : x > y ? 1 : 0;
  }

  getAllArrayItemsAsArray(array, key) {
    return array.map(itm => itm[key] || '');
  }
  getAllArrayItemsAsString(array: any[], key) {
    try {
      return array.reduce((acc, itm) => acc.concat(itm[key] || ''), '');
    } catch (error) {
      return '';
    }
  }

  private getGridColumnSize(width: string) {
    if (!width) {
      return 'minmax(0, 1fr)';
    }
    if (width.includes('px')) {
      return width;
    }

    const frSuffix = width.includes('fr') ? '' : 'fr';
    return `minmax(0, ${width}${frSuffix})`;
  }

  private setDataAndUpdatePaginator(data: any[], pageSize: number, pageIndex: number) {
    if (data) {
      this.data = data;
      this.originalData = JSON.parse(JSON.stringify(this.data));

      if (data.length < pageSize) {
        this.length = pageSize * pageIndex + data.length;
      } else if (pageSize * (pageIndex + 2) > this.length) {
        this.length = pageSize * (pageIndex + 2);
      }
    }
  }

  public onSearchChange(text: string) {
    this.searchChanged$.next(text);
  }

  public triggerReload() {
    this.reloadTrigger$.next(null);
  }

  public pageChange(event: PageEvent) {
    this.pageChanged$.next(event);
  }

  public getInitPageEvent(): PageEvent {
    return { pageIndex: this.pageIndex, pageSize: this.pageSize } as PageEvent;
  }

  trackByItemFn(index, item) {
    return item.id || index;
  }

  showFilterOptions(header: SimpleTableHeader) {
    if (!header.filterType) {
      return;
    }

    if (header.filterType === 'custom') {
      this.showFilter = '';
      header.customFilterConfig?.filterClick(this);
      this.applyFilter();
    } else {
      this.showFilter = this.showFilter === header.key ? '' : header.key;
    }
  }

  getDropdownVals(array) {
    if (this.serverside) {
      if (!array?.values) {
        console.error('No values defined for column: ', array?.key);
        return [];
      }

      return array.values;
    }

    const { special } = array;
    const { key } = array;

    let dropvals = [];
    switch (special) {
      case 'name':
        dropvals = this.originalData.map(itm => `${itm[key]?.name?.firstname} ${itm[key]?.name?.lastname}`);
        break;
      case 'othername':
        dropvals = this.originalData.map(itm => `${itm[key]?.firstname} ${itm[key]?.lastname}`);
        break;
      case 'match':
        dropvals = this.originalData.map(itm => array.matchItems[itm[key]]);
        break;
      case 'label':
      case 'role':
        dropvals = this.originalData.reduce((acc, itm) => [...acc, ...this.getAllArrayItemsAsArray(itm[key], 'name')], []);
        break;
      case 'path':
        dropvals = this.originalData.map(itm => {
          let val = itm[key];
          const path = special.split('|').slice(1, special.split('|').length);
          for (const item of path) {
            val = val[item] ? val[item] : '';
          }
          return val;
        });
        break;
      case 'list':
        dropvals = array.values;
        break;
      default:
        dropvals = this.originalData.map(itm => itm[key]);
        break;
    }

    return [...new Set(dropvals)]; // only unique values
  }

  applyFilter() {
    if (this.serverside) {
      this.serversideFiltering();
    } else {
      this.localFiltering();
    }
  }

  private serversideFiltering() {
    let filterTexts = [];
    // eslint-disable-next-line guard-for-in
    for (const key in this.filterVariables) {
      // get info
      const headerItem = (this.header as SimpleTableHeader[]).find(item => item.key === key.split('---')[0]);

      const value = this.filterVariables[key];

      if (!this.filterValid(value, headerItem)) {
        continue;
      }
      switch (headerItem.filterType) {
        case 'input':
          filterTexts.push(`${this.filterPrefix}${headerItem.key}=${encodeURIComponent(value as string | number)}`);
          break;
        case 'date': {
          const dt = new Date(value);
          if (typeof dt.toISOString === 'function' && dt.toString() !== 'Invalid Date') {
            filterTexts.push(`${this.filterPrefix}${key}=${dt.toISOString()}`);
          } else {
            this.filterVariables[key] = [];
          }
          break;
        }
        case 'dropdown':
          for (const dropdownValue of value as string[]) {
            filterTexts.push(`${this.filterPrefix}${headerItem.key}=${encodeURIComponent(dropdownValue)}`);
          }
          break;
        case 'customDropdown':
        case 'custom':
          filterTexts.push(headerItem.customFilterConfig?.filterFunction(this));
          break;
      }
    }

    filterTexts = filterTexts?.filter(text => !!text);
    this.filterChanged$.next(filterTexts?.join('&'));
  }

  private filterValid(value: any, header: SimpleTableHeader): boolean {
    return (typeof value === 'string' ? !!value : value !== undefined && value !== null) || header.filterType === 'custom';
  }

  private localFiltering() {
    if (!this.originalData?.length) {
      this.originalData = this.data;
    }

    let newData = JSON.parse(JSON.stringify(this.originalData));
    const hObjs = this.headerObjects;
    for (const keyVal in this.filterVariables) {
      if (!this.filterVariables.hasOwnProperty(keyVal)) {
        continue;
      }

      const elem = this.filterVariables[keyVal];
      const { special } = (hObjs as any).find(itm => itm.key === keyVal.split('---')[0]);

      if (typeof elem === 'string' && elem) {
        newData = this.filterByText(newData, special, elem, keyVal);
      } else if (elem && typeof elem === 'object' && elem.length > 0 && special === 'list') {
        newData = this.filterByList(newData, special, elem, keyVal);
      } else if (elem && typeof elem === 'object' && (elem.length > 0 || special === 'date')) {
        newData = this.filterByObject(newData, special, elem, keyVal);
      }
    }

    this.data = newData;
  }

  private filterByList(data, special, elem, keyVal) {
    return data.filter(itm => elem.includes(itm[keyVal]));
  }

  private filterByText(data, special: string, elem: string, keyVal: string) {
    return special && special.startsWith('path')
      ? data.filter(itm => this.getDeepestMatch(itm, special)?.toLowerCase().includes(elem?.toLowerCase()))
      : data.filter(itm => itm[keyVal]?.toLowerCase().includes(elem?.toLowerCase()));
  }

  private getDeepestMatch(obj: Record<string, unknown>, path: string): string {
    const attributes = path.split('|').slice(1);
    return attributes.reduce((acc, key) => (acc ? acc[key] : obj[key]) || '', null);
  }

  private filterByObject(data, special, elem, keyVal) {
    switch (special) {
      case 'name':
        return data.filter(itm => elem.includes(`${itm[keyVal].name.firstname} ${itm[keyVal].name.lastname}`));
      case 'othername':
        return data.filter(itm => elem.includes(`${itm[keyVal]?.firstname} ${itm[keyVal]?.lastname}`));
      case 'label':
      case 'role':
        return data.filter(itm => elem.some(item => this.getAllArrayItemsAsArray(itm[keyVal], 'name').includes(item)));
      case 'date':
        return data.filter(itm => {
          const dataDate = new Date(itm[keyVal.split('---')[0]]).getTime();
          const searchDate = new Date(elem).getTime();
          return keyVal.split('---')[1] === 'From' ? dataDate >= searchDate : dataDate <= searchDate;
        });
      case 'match': {
        const { matchItems } = (this.header as any).find(itm => itm.key === keyVal);
        const selected = elem.map(itm => matchItems.findIndex(item => item === itm));
        return data.filter(itm => selected.indexOf(itm[keyVal]) > -1);
      }
      default:
        return data.filter(itm => elem.indexOf(itm[keyVal]) > -1);
    }
  }

  async onDrop(event: CdkDragDrop<any[]>) {
    const filter = this.filterChanged$.value;
    const sorting = this.sortingChanged$.value;
    const page = this.pageChanged$.value;
    const userIsAllowedToDrag = !filter && !sorting && !page.pageIndex;
    const item = this.data[event.previousIndex];

    if (!userIsAllowedToDrag) {
      return this.dialog
        .open(ConfirmationDialogComponent, {
          disableClose: true,
          data: {
            headline: 'Info',
            msg: `Dragging not allowed`,
            cancelMsg: 'Go Back',
            confirmMsg: 'Reset',
            translate: true
          }
        })
        .afterClosed()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(confirm => {
          if (!confirm) {
            return;
          }

          this.clearFilter();
          this.clearSorting();
          this.resetPage();

          this.toast.info('Table filters and sorting resetted.');
        });
    }

    if (event.previousIndex === event.currentIndex) {
      return;
    }

    moveItemInArray(this.data, event.previousIndex, event.currentIndex);
    this.itemDrop.emit(item);
  }

  sortPredicate(index: number, item: CdkDrag<any>): boolean {
    // const newPositionElement = this.data[index];
    // const indexOfDefaultRow = 1;
    // // const indexOfDefaultRow = this.data?.findIndex(
    // //   (item) => item.type === MeritPlanType.Default
    // // );
    // const canMove =
    //   item?.data?.isSeparator || !newPositionElement?.isDragDisabled;

    // if (indexOfDefaultRow !== -1) {
    // }
    return false;
  }

  filterSet() {
    for (const key in this.filterVariables) {
      if (!this.filterVariables.hasOwnProperty(key)) {
        continue;
      }

      const elem = this.filterVariables[key];
      // eslint-disable-next-line no-constant-condition
      if (typeof elem) {
        return true;
      }
    }
    return false;
  }

  filterActive(key): boolean {
    return Object.entries(this.filterVariables).some(prevFilter => prevFilter[0].includes(key) && prevFilter[1]);
  }
}
