import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@coin/shared/util-environments';
import { EnvironmentTypeEnum } from '@coin/importer/common/util';
import { LogsService } from '../../services/logs/logs.service';

@Component({
  selector: 'ci-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class HeaderComponent {
  get currentEnvironment() {
    return environment.type !== EnvironmentTypeEnum.Prod ? environment : null;
  }

  constructor(
    private logsService: LogsService,
    private router: Router
  ) {}

  public gotoStartPage() {
    this.router.navigate(['/'], {
      queryParams: {
        page: null,
        limit: null,
        filter: null,
        sort: null,
        search: null
      },
      queryParamsHandling: 'merge'
    });
    this.logsService.resetTable(true);
  }
}
