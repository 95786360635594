import { animate, sequence, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Fn } from '@coin/shared/util-models';

@Component({
  selector: 'coin-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ],
  animations: [
    trigger('anim', [
      transition('unchecked => checked', [
        style({ width: 0, height: 0, opacity: 0 }),
        sequence([
          animate('.25s ease-in', style({ width: '100%', height: '100%', opacity: 1 })),
          animate('.1s cubic-bezier(0.215, 0.61, 0.355, 1)', style({ width: '80%', height: '80%', opacity: 1 }))
        ])
      ]),
      transition('checked => unchecked', [style({ width: '80%', height: '80%', opacity: 1 }), sequence([animate('.3s ease-in-out', style({ width: 0, height: 0, opacity: 0 }))])])
    ])
  ],
  standalone: false
})
export class CheckboxComponent implements AfterViewInit, ControlValueAccessor {
  @Input() disabled = false;
  @Input() addClass: string;
  @Input() size = 24;
  @Input() contrast = false;
  @Input() dark = false;
  @Input() disabledSelfToggle = false;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onToggle: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('checkboxText') text: ElementRef;

  public hasText = false;
  public checkedValue = false;

  @Input()
  set checked(isChecked: boolean) {
    // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this.checkedValue = isChecked;
    this.onChange(isChecked);
    this.onTouch(isChecked);
  }

  get checked() {
    return this.checkedValue;
  }

  constructor() {}

  onChange: Fn = () => {};
  onTouch: Fn = () => {};

  writeValue(value: boolean): void {
    this.checked = value;
  }
  registerOnChange(fn: Fn): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: Fn): void {
    this.onTouch = fn;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.hasText = this.text && this.text.nativeElement && this.text.nativeElement.childNodes.length > 0;
    });
  }

  clicked() {
    if (this.disabled) {
      return;
    }
    this.checked = this.disabledSelfToggle ? this.checked : !this.checked;
    this.onToggle.emit(this.checked);
  }
}
