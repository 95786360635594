import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FileTypeDto, IRepublishObjectData, State } from '@coin/importer/dto/util';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { Subject, Subscription } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { takeUntil } from 'rxjs/operators';
import { ToastService } from '../../services/toast/toast.service';
import { FileTypeService } from '../../services/file-type/file-type.service';
import { ObjectRefreshService } from '../../services/object-refresh/object-refresh-service';
import { RepublishService } from '../../services/republish/republish.service';

@Component({
  selector: 'ci-object-refresher-popup',
  templateUrl: './object-refresher-popup.component.html',
  styleUrls: ['./object-refresher-popup.component.scss'],
  standalone: false
})
export class ObjectRefresherPopupComponent implements OnInit, OnDestroy {
  objectIdentifiers = {};
  selectedFileTypePrefix: string = null;
  selectedFileTypeKeys: string[];
  fileTypeKeys = [];
  publishing = false;
  entities: FileTypeDto[];
  progressMode: ProgressBarMode = 'determinate';
  uploadSubscription: Subscription;
  loading = true;
  fullBaseActive = true;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public matDialogRef: MatDialogRef<ObjectRefresherPopupComponent>,
    private toast: ToastService,
    private fileTypeService: FileTypeService,
    private objectRefreshService: ObjectRefreshService,
    private republishService: RepublishService
  ) {}

  ngOnInit(): void {
    this.fileTypeService.fileTypeSubject$.pipe(takeUntil(this.destroy$)).subscribe(fileTypes => {
      this.loading = false;
      this.entities = fileTypes;
      this.fileTypeKeys = Object.keys(fileTypes);
    });
  }

  async applySelectionChange(event: HTMLSelectElement) {
    // @ts-ignore
    this.selectedFileTypePrefix = event.value.prefix;
    const selectedFileTypeIdentifier = this.entities.find(entity => entity.prefix === this.selectedFileTypePrefix);
    this.selectedFileTypeKeys = selectedFileTypeIdentifier.identifier.split(',');
    this.objectIdentifiers = {};
    this.selectedFileTypeKeys.forEach(key => {
      this.objectIdentifiers[key] = undefined;
    });
  }

  onToggle(event: boolean) {
    this.fullBaseActive = event;
    if (event) {
      Object.keys(this.objectIdentifiers).forEach(key => {
        this.objectIdentifiers[key] = null;
      });
    }
  }

  isValid() {
    return this.fullBaseActive || !(!this.fullBaseActive && Object.values(this.objectIdentifiers).filter(value => !value).length);
  }

  dropdownDisplayFn(entitiy: FileTypeDto): string {
    return entitiy.displayedName;
  }

  close() {
    if (!this.publishing) {
      this.matDialogRef.close();
    } else {
      this.cancelUpload();
    }
  }

  save() {
    if (!this.fullBaseActive) {
      for (const [key, value] of Object.entries(this.objectIdentifiers)) {
        if (!value) {
          this.toast.warn(`Please enter a value for ${key}`, 'Required Value Missing');
          return;
        }
      }
    }

    this.publishing = true;
    const republishObjectData: IRepublishObjectData = {
      id: uuid(),
      fileTypePrefix: this.selectedFileTypePrefix,
      identifiers: this.fullBaseActive ? null : this.objectIdentifiers
    };
    this.objectRefreshService
      .sendRepublishObjectData(republishObjectData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.publishing = false;
        this.toast.info('Object Refreshing Request successfully Delivered');
        this.close();
      }),
      this.addTemporaryLog(republishObjectData);
    error => {
      this.toast.error('Error while refreshing object');
      this.publishing = false;
      this.selectedFileTypePrefix = null;
      this.selectedFileTypeKeys = [];
      console.log(error);
    };
  }

  private cancelUpload() {
    this.publishing = false;
    this.selectedFileTypePrefix = null;
    this.objectIdentifiers = {};
    this.toast.info('Upload cancelled');
  }

  disableOption(item: FileTypeDto): boolean {
    return !item.numberOfLogs;
  }

  disableComment(): string {
    return ` (no previous uploads)`;
  }

  private addTemporaryLog(republishObjectData: IRepublishObjectData): void {
    this.republishService.setTemporaryLog({
      id: republishObjectData.id,
      baseUrl: '',
      fileType: this.entities.find(entity => entity.prefix === republishObjectData.fileTypePrefix),
      state: State.Pending,
      republishedAt: new Date(),
      republishedBy: null
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
