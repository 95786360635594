import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileName',
  standalone: false
})
export class FileNamePipe implements PipeTransform {
  transform(value: unknown = ''): string {
    let result = '';
    if (typeof value === 'string') {
      const splitted = value.split('/');
      if (splitted.length) {
        [result] = splitted.pop().split('?');
      }
    }
    return result;
  }
}
