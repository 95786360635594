import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'ci-button-footer',
  templateUrl: './button-footer.component.html',
  styleUrls: ['./button-footer.component.scss'],
  standalone: false
})
export class ButtonFooterComponent {
  @Input() buttonsLeftTemplate: TemplateRef<unknown>;
  @Input() buttonsRightTemplate: TemplateRef<unknown>;
  @Input() buttonsCenterTemplate: TemplateRef<unknown>;
  @Input() noHorizontalPadding: boolean;
}
