import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileFormat, FileTypeDto } from '@coin/importer/dto/util';
import { PopupAction } from '@coin/importer/common/util';
import { SimpleTableComponent, SimpleTableHeader } from '../simple-table/simple-table.component';
import { FileTypeService } from '../../services/file-type/file-type.service';
import { FileTypePopupComponent } from '../file-type-popup/file-type-popup.component';
import { ExportAttributesPopupComponent } from '../export-attributes-popup/export-attributes-popup.component';
import { ToastService } from '../../services/toast/toast.service';
import { SchemaValidationsPopupComponent } from '../schema-validations-popup/schema-validations-popup.component';

@Component({
  selector: 'ci-filetype-overview',
  templateUrl: './filetype-overview.component.html',
  styleUrls: ['./filetype-overview.component.scss'],
  standalone: false
})
export class FiletypeOverviewComponent {
  @ViewChild(SimpleTableComponent) simpleTable: SimpleTableComponent;
  @Input() loading;
  public disabled = {};

  constructor(
    private _matDialog: MatDialog,
    public fileTypeService: FileTypeService,
    private toast: ToastService
  ) {}

  public TYPES: string[] = Object.values(FileFormat);

  checkedItem: FileTypeDto;

  public tableHeader: SimpleTableHeader[] = [
    {
      title: '',
      hideSorting: true,
      width: '90px'
    },
    {
      title: 'Name',
      key: 'displayedName',
      width: '3fr'
    },
    {
      title: 'File Format',
      key: 'fileFormat',
      filterType: 'dropdown',
      special: 'list',
      values: Object.values(FileFormat),
      width: '1fr'
    },
    {
      title: 'Exported Attributes',
      key: 'exportAttributes',
      hideSorting: true,
      width: '1fr'
    },
    {
      title: 'import threshold',
      key: 'threshold',
      hideSorting: true,
      width: '1fr'
    },
    {
      title: 'Retention Days',
      key: 'retentionTime',
      hideSorting: true,
      width: '1fr'
    },
    {
      title: 'Email Notification',
      key: 'emailAddress',
      hideSorting: true,
      width: '1fr'
    },
    {
      title: 'Validations',
      hideSorting: true,
      width: '1fr'
    }
  ];

  openEditFileTypePopup(item: FileTypeDto): void {
    this._matDialog.open(FileTypePopupComponent, {
      data: { item, action: PopupAction.Edit }
    });
  }

  openDeleteFileTypePopup(item: FileTypeDto): void {
    if (item.numberOfLogs) {
      this.toast.info('cannot delete a File Type with already associated Logs');
    } else {
      this._matDialog.open(FileTypePopupComponent, {
        data: { item, action: PopupAction.Delete }
      });
    }
  }

  openEditAttributesPopup(item: FileTypeDto): void {
    this._matDialog.open(ExportAttributesPopupComponent, {
      data: { item, action: PopupAction.Edit }
    });
  }

  getThreshold(item: FileTypeDto): string {
    return item.threshold ? item.threshold.toString() : '';
  }

  activateDisable(fileType: FileTypeDto) {
    this.disabled[fileType.id] = true;
  }

  deactivateDisable(fileType: FileTypeDto) {
    this.disabled[fileType.id] = false;
  }

  isDisabled(fileType: FileTypeDto): boolean {
    return !!this.disabled[fileType.id];
  }

  updateCustomValidation(item: FileTypeDto): void {
    const ret = this._matDialog.open(SchemaValidationsPopupComponent, {
      data: { item }
    });
  }
}
