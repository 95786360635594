import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentage',
  standalone: false
})
export class PercentagePipe implements PipeTransform {
  transform(p: string | number, t: string | number): string {
    const partial = parseFloat(p?.toString());
    const total = parseFloat(t?.toString());

    if (isNaN(partial) || isNaN(total)) return '';
    if (total === 0) return '0%';

    const percentage = Math.round((partial / total) * 100 * 10) / 10;

    return `${percentage}%`;
  }
}
